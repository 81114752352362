import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import TimelineIcon from '@mui/icons-material/Timeline';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import * as nav from '@repo-ui/nav';

export const mainNavManager: nav.MenuManager = nav.configureSideMenus([
  {
    type: 'menu',
    icon: <DashboardIcon />,
    title: '홈',
    href: '/',
  },
  {
    type: 'menu',
    icon: <DriveFolderUploadIcon />,
    title: '실험실',
    href: '/dev-labs',
  },
  {
    type: 'menu',
    icon: <DriveFolderUploadIcon />,
    title: '스타일보드',
    href: '/style-board',
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: '교과목',
    submenus: [
      {
        type: 'menu',
        title: '수학',
        href: '/subject/math',
      },

      {
        type: 'menu',
        title: '물리',
        href: '/subject/phy',
      },

      {
        type: 'menu',
        title: '정보',
        href: '/subject/info',
      },
    ],
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: 'AI학습',
    submenus: [
      {
        type: 'menu',
        title: 'AI학습',
        href: '/aitrain/aitrain',
      },

      {
        type: 'menu',
        title: '데이터 과학',
        href: '/aitrain/data_science',
      },
    ],
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: '코딩',
    submenus: [
      {
        type: 'menu',
        title: '코딩하기',
        href: '/coding/dashboard',
      },
      {
        type: 'menu',
        title: '튜터',
        href: '/tutor/intro',
      },
      {
        type: 'menu',
        title: '나의 코딩',
        href: '/coding/mine',
      },
    ],
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: '커뮤니티',
    submenus: [
      {
        type: 'menu',
        title: '클래스룸',
        href: '/classroom',
      },

      {
        type: 'menu',
        title: '스터디그룹',
        href: '/studygroup',
      },

      {
        type: 'menu',
        title: '오픈강좌',
        href: '/openlecture',
      },

      {
        type: 'menu',
        title: '갤러리',
        href: '/gallery/list',
      },
    ],
  },

  {
    type: 'section',
    icon: <TimelineIcon />,
    title: 'About Us',
    submenus: [
      {
        type: 'menu',
        title: 'O-CODE',
        href: '/intro',
      },

      {
        type: 'menu',
        title: '공지사항',
        href: '/notice',
      },

      {
        type: 'menu',
        title: 'FAQ',
        href: '/faq',
      },

      {
        type: 'menu',
        title: '문의',
        href: '/inquiry',
      },
    ],
  },
]);
