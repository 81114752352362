export const MENU_SECTIONS = [
  { menuId: 'subject', title: '교과목' },
  { menuId: 'ai', title: 'AI학습' },
  { menuId: 'block', title: '코딩' },
  { menuId: 'community', title: '커뮤니티' },
  { menuId: 'about', title: 'About Us' },
];
interface SUBMENU_TYPE {
  title: string;
  href: string;
  match?: (path: string) => boolean;
}

export const SUBMENUS_ARRAY: SUBMENU_TYPE[][] = [
  [
    { title: '수학', href: '/subject/math' },
    { title: '물리', href: '/subject/phy' },
    { title: '정보', href: '/subject/info' },
  ],
  [
    { title: 'AI 학습', href: '/ai-learn/list/supervised' },
    { title: '데이터 과학', href: '/ai-learn/list/data-science' },
    { title: '실험실', href: '/dev-labs' },
  ],
  [
    { title: '코딩하기', href: '/coding/dashboard' },
    { title: '나의 코딩', href: '/coding/mine' },
    { title: '튜터', href: '/tutor/intro' },
  ],
  [
    { title: '클래스룸', href: '/classroom' },
    { title: '스터디그룹', href: '/studygroup/bandlist/all' },
    { title: '오픈강좌', href: '/openlecture' },
    { title: '갤러리', href: '/gallery/list' },
  ],

  [
    { title: 'O-CODE', href: '/intro' },
    { title: '공지사항', href: '/notice' },
    { title: 'FAQ', href: '/faq' },
    { title: '문의', href: '/inquiry' },
  ],
];
